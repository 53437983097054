var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Subclass Translation" } }, [_c("select-input", { key: "countryId", attrs: { "form-item": "", "label": "Country", "placeholder": "Select Country", "data-source": _vm.countries, "source": "countryId", "source-label": "country", "value": _vm.country, "disabled": true, "span": 4 } }), _c("select-input", { key: "subclass", attrs: { "placeholder": "Select Subclass", "data-source": _vm.listSubclass, "label": "Subclass", "source": "subClass", "source-label": "subClassDesc", "rules": "required", "span": 10 } }), _c("select-input", { key: "projectTypeId", attrs: { "placeholder": "Select Project Type", "data-source": _vm.listProjectType, "label": "Project Type", "source": "projectTypeId", "source-label": "projectType", "rules": "required", "span": 10, "default-active-first-option": "" } })], 1);
};
var staticRenderFns$1 = [];
var CreateProjectSubclassTranslation_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "CreateProjectSubclassTranslation",
  mixins: [MyUtil],
  inject: ["crud"],
  data() {
    return {
      country: this.crud.getQueryString("CountryID").value,
      listProjectType: [],
      listSubclass: []
    };
  },
  created() {
    this.getCountries();
    this.getListProjectType();
    this.getListSubclass();
    this.storeValue("countryId", this.country);
  },
  methods: {
    getListProjectType() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-project-type`).then((res) => {
        this.listProjectType = [...res.data];
      });
    },
    getListSubclass() {
      this.axios.get(`${this.apiUrl}/ppv/common/ppv-sub-class?CountryID=${this.country}`).then((res) => {
        this.listSubclass = [...res.data];
      });
    },
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateProjectSubclassTranslation = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-sub-class" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-project-type" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.project-subclass-translations", "redirect-route": "/ppv-actuals/project-subclass-translations" } }, [_c("create-project-subclass-translation")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateProjectSubclassTranslation
  },
  data() {
    return {
      apiUrl,
      CreateProjectSubclassTranslation
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
